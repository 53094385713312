import './App.css';

function App() {
  return (
    <div className="App">

      <div id="wrapper">

        <header id="header" className="alt">
          <span className="logo"><img className="box-shadow" src="images/ofkd_logo.png" alt="OFKD Logo" /></span>
          <h1 className="text-shadow">Országos Felsőoktatási Környezettudományi Diákkonferencia</h1>
          <p className="year">2022</p>
        </header>

        <nav id="nav">
          <ul>
            <li><a href="#felhivas" className="active">Felhívás</a></li>
            <li><a href="#regisztracio">Regisztráció</a></li>
            <li><a href="#szervezobizottsag">Szervezőbizottság</a></li>
            <li><a href="#tudomanyosbizottsag">Tudományos bizottság</a></li>
            <li><a href="#hataridok">Határidők</a></li>
            <li><a href="#kapcsolat">Kapcsolat</a></li>
          </ul>
        </nav>

        <div id="main" className="box-shadow">

          <section id="felhivas" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Kedves Diákok, Hallgatók és Kollégák!</h2>
                </header>
                <p>
                  A XVIII. Országos Felsőoktatási Környezettudományi Diákkonferenciát a Pécsi Tudományegyetem Természettudományi Kara rendezi. A rendezvény kizárólag online formában valósul majd meg. A konferenciára egyrészt magyar nyelvű dolgozatokat várunk, amelyek időszerű környezettudományi, illetve a környezettel kapcsolatos alkalmazott tudományterületi kutatás eredményeit tartalmazzák. A dolgozatok hossza minimum 30, maximum 50 oldal lehet mellékletekkel együtt. Várjuk emellett az élő vagy élettelen környezethez kapcsolódó művészeti alkotások bemutatását is. Olyan művekkel lehet pályázni, amelyeket felsőoktatási intézmény (BSc/BA, MSc/MA, osztatlan stb.) képzésében részt vevő hallgató a 2019/2020. tanév második félévében, illetve a 2020/2021. vagy a 2021/2022. tanévben készített. Bíztatjuk a határon túli felsőoktatási intézmények megadott témakörökben kutató hallgatóit is, hogy eredményeikkel nevezzenek a konferenciára. Lehetőséget adunk továbbá az intézmények angol képzéseiben résztvevő hallgatóknak is, hogy angol nyelvű dolgozatokkal vegyenek részt a konferencián. Ezek esetén a pályamunkák értékelése és bemutatásuk angol nyelven történik.
                </p>

                <img className="ttk image-shadow" src="images/ofkd.png"/>

                <p>
                  A benevezett dolgozatok elfogadásának és értékelésének feltétele egyrészt az, hogy a környezettudomány szakterületeit érintő időszerű problémákhoz, feladatokhoz, fenntarthatósági megoldásához kapcsolódjon, másrészt, hogy a szerző(k) önálló, eredeti munkájuk eredményeit előzetesen mutassák be valamilyen intézményi megmérettetésen (pl. kari/intézményi szakterületi TDK konferencia), vagy eredményeikkel szerepeljenek egyéb tudományos társasági ülésen, konferencián. Védési bizottság előtt bemutatott és értékelt szakdolgozatok vagy diplomamunkák eredményeiből a kiírásnak megfelelően átdolgozott pályamunkát csak abban az esetben fogadunk be, ha a szerző ezekkel az eredményekkel korábbi OFKD vagy OTDK konferenciákon nem szerepelt (erről a szerző maga nyilatkozik).
                </p>

                <p>
                  A konferenciára középiskolás diákok jelentkezését is várjuk egyetemi mentoráláson keresztül, akik valamely környezettudományi témában önálló tudományos vagy művészeti célú munkával rendelkeznek, amit valamilyen középfokú oktatási megméretésen (pl. tanulmányi verseny, iskolai előadás) már bemutattak.
                </p>
                <p>
                  Az Országos Felsőoktatási Környezettudományi Diákkonferencián a tudományos szekciók a környezettudományok számos szakterületét reprezentálják, így korábbi konferenciák tapasztalatai alapján az alábbi javasolt szekcióbeosztást tesszük közzé:
                </p>
                <div className="card-list">
                  <div className="card box-shadow">Agrártudományok Szekció</div>
                  <div className="card box-shadow">Alkalmazott ökológia Szekció</div>
                  <div className="card box-shadow">Biodiverzitás - Zoológia Szekció</div>
                  <div className="card box-shadow">Biodiverzitás - Botanika Szekció</div>
                  <div className="card box-shadow">Biotechnológia Szekció</div>
                  <div className="card box-shadow">Erdőökológia, erdőgazdálkodás Szekció</div>
                  <div className="card box-shadow">Éghajlatváltozás és globális környezeti problémák Szekció</div>
                  <div className="card box-shadow">Földtudományok Szekció</div>
                  <div className="card box-shadow">Környezetanalitika Szekció</div>
                  <div className="card box-shadow">Környezeti mikrobiológia Szekció</div>
                  <div className="card box-shadow">Környezettechnológia Szekció</div>
                  <div className="card box-shadow">Környezet-egészségügy és Környezeti Nevelés Szekció</div>
                  <div className="card box-shadow">Művészet és környezet Szekció</div>
                  <div className="card box-shadow">Tájökológia Szekció</div>
                  <div className="card box-shadow">Tájtörténet és tájhasználat Szekció</div>
                  <div className="card box-shadow">Természetvédelem Szekció</div>
                  <div className="card box-shadow">Tér- és Geoinformatika Szekció</div>
                  <div className="card box-shadow">Toxikológia Szekció</div>
                  <div className="card box-shadow">Víztudományok Szekció</div>
                  <div className="card box-shadow">Environmental Science Session</div>
                </div>
                <p>
                  A szekciók végleges beosztását a beérkezett összefoglalók ismeretében az OFKD 2022 Tudományos Bizottságának javaslatai alapján végezzük.
                </p>
              </div>
            </div>
          </section>

          <section id="regisztracio" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>A jelentkezés módja és feltételei</h2>
                </header>
                <p>
                  <b>???</b>
                  Látogasson el a <a href="https://www.ttk.pte.hu/">PTE TTK honlapjára</a> és a válassza az OFKD2022 menüpontot, ami a XVIII. Országos Felsőoktatási Környezettudományi Diákkonferencia honlapjára irányítja.
                  <b>???</b>
                </p>
                <p>
                  Regisztráljon a lebonyolítást segítő CourseGarden rendszerben. A regisztráció során meg kell adnia a nevét és e-mailcímét.  A rendszer ellenőrzi és visszaigazolja a regisztrációt.
                </p>
                <a href="https://ofkd.coursegarden.com/InviteLink/oLBfnLJFkvAcoW" className="button btn-reg">Regisztráció</a>
                <p>
                  Az ofkd.coursegarden.com oldalra történő belépést követően a pályamunkával jelentkező hallgatók a Fogadótérben a Nevezési lapon a dolgozat adatainak pontos rögzítésével nevezhetnek (cím, szerző(k), témavezető(k), küldő intézmény, dolgozat kivonata)
                </p>
                <p>
                  A dolgozat kivonatát legkésőbb 2022. március 4. 24 óráig kell feltölteni. A kivonat maximális hossza (cím és szerző nélkül) szóközökkel együtt 3 000 leütés; a kivonat ne tartalmazzon ábrát és táblázatot. A kivonat végén a szerzők illesszék be a témavezető(k)re vonatkozó információt (név, beosztás, egyetem, kar, tanszék). A kivonatot célszerű szövegszerkesztőben véglegesre elkészíteni, majd ezt követően beilleszteni a honlapfelületre.
                </p>
                <p>
                  A dolgozat végleges példányát egyetlen állományba (PDF) rendezve legkésőbb 2022. március 25. 24 óráig kell feltölteni a CourseGarden.com oldalra. A feltölthető maximális fájlméret 20 MB. A feltöltött fájl elnevezése: hallgató neve_egyetem/iskola_kar rövidítése (pl. Keresztes_Virag_PTE_TTK.pdf). A dolgozatot papír alapon nem kell benyújtani, továbbá e-mailen vagy postai úton beküldött dolgozatokat nem fogadunk el.
                </p>
                <h3>A Konferencia nevezési díja</h3>
                <p>
                  A Diákköri Konferencia nevezési díja dolgozatonként 5000 Ft. A nevezési díjból a konferenciaszervezés költségeit fedezzük. Mivel a konferencia online valósul meg, nincs részvételi díj.
                </p>
                <h3>Intézményi igazolás</h3>
                <p>
                  A dolgozatokhoz csatolni kell egy intézményi igazolást, ami igazolja a hallgató helyi fordulón történő előzetes megmérettetését.
                </p>
                <p className="mb-1">
                  Az intézményi igazolásnak két formája lehet:
                </p>
                <ul>
                  <li>Csoportos: egy, az egyetem, kar vagy intézet által kiállított, az OFKD-n induló saját hallgatóit összesítő igazolás (igazolásminta letölthető a honlapról).</li>
                  <li>Egyéni: a hallgató egyetemétől kért, saját nevére szóló egyéni igazolás. Az igazolásnak tartalmaznia kell a hallgató(k) adatait (név, telefonszám, e-mail cím, szak, szakirány), a dolgozat címét, a témavezető(k) adatait (név, intézmény, tudományos fokozat), a korábbi tudományos megmérettetés nevét, helyét, idejét, az ott elért eredményt/pontszámot, valamint a témavezető(k) aláírását.</li>
                </ul>
                <p>Az igazolásokat az <a href="mailto:ofkd2022@gmail.com">ofkd2022@gmail.com</a> elektronikus levélcímre várjuk.</p>
                <p className="mb-1">Röviden összefoglalva a konferenciarészvétel feltételei:</p>
                <ul>
                  <li>Egyéni regisztráció és a Nevezési lap kitöltése.</li>
                  <li>Dolgozat feltöltése.</li>
                  <li>Az intézményi igazolás és egyéb igazolás és nyilatkozat beküldése.</li>
                  <li>A nevezési díj határidőre történő befizetése.</li>
                </ul>
                <h3>Tudományos szekciók, zsűrik és bírálat</h3>
                <p>
                  A nevezett dolgozatokat a feltöltött kivonatok alapján a különböző tudományterületek képviselőiből álló Tudományos Bizottság sorolja szekciókba. A szekciókhoz háromfős zsűrit kér fel, majd ezt követően kiküldi a dolgozatokat a zsűritagoknak bírálatra. A beérkezett bírálatokat 2022. április 27-ig feltöltjük a résztvevők számára a rendszerbe, melyről a dolgozat szerzője számára értesítés érkezik.
                </p>
                <p>
                  A szekciók kialakításával egyidejűleg kialakul a Konferencia végleges programja is.
                </p>
                <h3>Tudományos előadás</h3>
                <p>
                  A konferencián a szerzők 15 perces előadást tartanak, amelyet 10 perc vita követ. Az előzetes zsűrizés és az előadások értékelése alapján a legjobbnak ítélt pályaművek szerzői a konferencia támogatói és a Szervező Bizottság által adományozott díjazásban részesülhetnek.
                </p>
              </div>
            </div>
          </section>

          <section id="szervezobizottsag" className="main special">
            <header className="major">
              <h2>Szervezőbizottság</h2>
            </header>
            <ul className="features">
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Dr. Geresdi István</h3>
                <p>egyetemi tanár </p>
                <p>Szervezőbizottság elnöke</p>
              </li>
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Dr. Szemethy László</h3>
                <p>egyetemi tanár</p>
                <p>Szervezőbizottság társelnöke</p>
              </li>
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Dr. Ősz Katalin</h3>
                <p>egyetemi docens</p>
                <p>Szervezőbizottsági titkár</p>
              </li>
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Dr. Horváth Győző</h3>
                <p>egyetemi docens</p>
                <p>Szervezőbizottsági titkár</p>
              </li>
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Dr. Dezső József</h3>
                <p>egyetemi adjunktus</p>
                <p>Szervezőbizottsági titkár</p>
              </li>
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Dr. Reményi Péter</h3>
                <p>egyetemi docens</p>
                <p>Szervezőbizottsági tag</p>
              </li>
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Dr. Sebe Krisztina</h3>
                <p>egyetemi docens</p>
                <p>Szervezőbizottsági tag</p>
              </li>
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Dr. Kurucz Kornélia</h3>
                <p>egyetemi adjunktus</p>
                <p>Szervezőbizottsági tag</p>
              </li>
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Kovács Mónika</h3>
                <p>PhD koordinátor</p>
                <p>Szervezőbizottsági tag</p>
              </li>
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Puhl-Rezsek Marietta</h3>
                <p>PhD hallgató</p>
                <p>Szervezőbizottsági tag</p>
              </li>
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Kelemen Krisztina</h3>
                <p>PhD hallgató</p>
                <p>Szervezőbizottsági tag</p>
              </li>
              <li>
                <div className="icon major fa-solid fa-user"/>
                <h3>Szabó Rebeka</h3>
                <p>PhD hallgató</p>
                <p>Szervezőbizottsági tag</p>
              </li>
            </ul>
          </section>

          <section id="tudomanyosbizottsag" className="main special">
            <header className="major">
              <h2>Tudományos bizottság</h2>
            </header>
            <footer className="major">
              <ul className="actions special">
                <li><a href="" className="button btn-more">A tudományos bizottság tagjai</a></li>
              </ul>
            </footer>
          </section>

          <section id="hataridok" className="main special">
            <header className="major">
              <h2>Határidők</h2>
            </header>

            <div className="table-wrapper">
              <table id="hatarido">
                <thead>
                  <tr>
                    <th/>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2022. március 16.</td>
                    <td>Pályázó hallgatók nevezése, dolgozatkivonatok feltöltése</td>
                  </tr>
                  <tr>
                    <td>2022. március 4. - május 25.</td>
                    <td>A résztvevő hallgatóság honlapi regisztrációja</td>
                  </tr>
                  <tr>
                    <td>2022. március 25.</td>
                    <td>Intézményi nevezések beérkezése</td>
                  </tr>
                  <tr>
                    <td>2022. március 25.</td>
                    <td>Dolgozatok véglegesítése és feltöltése</td>
                  </tr>
                  <tr>
                    <td>2022. március 25. - április 25.</td>
                    <td>Dolgozatok bírálata</td>
                  </tr>
                  <tr>
                    <td>2022. április 15.</td>
                    <td>Nevezési díj befizetése, igazolás megküldése</td>
                  </tr>
                  <tr>
                    <td>2022. április 29.</td>
                    <td>Szakmai program összeállítása</td>
                  </tr>
                  <tr>
                    <td>2022. május 9 - 13.</td>
                    <td>Bírálatok megküldése a pályázóknak</td>
                  </tr>
                  <tr>
                    <td>2022. május 16 - 18.</td>
                    <td>
                      Egyéb résztvevők regisztrációja.
                      Műalkotások prezentációjának feltöltése
                      Konferencia
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              A konferencián történő részvétel nem előadók számára ingyenes, de honlapi regisztrációhoz kötött (www.ofkd2022.ttk.pte.hu vagy ofkd.coursegarden.com).
            </p>

            <p>
              Pénzügyi információ: A nevezési díjak fizetése a Pécsi Tudományegyetem felé történik. A jelentkezési űrlapon kérjük jelezzék, hogy mely számlázási névre és címre kérik a proforma számla kiállítását. Nem természetes személy esetében kérjük az adószám megadását is.
            </p>

          </section>

        </div>

        <footer id="footer">
          <section id="kapcsolat">
            <h2>Kapcsolat</h2>
            <p>
              Kérjük a Konferencia iránt érdeklődő hallgatókat, hogy minél hamarabb regisztráljanak a
              honlapon, majd a határidők betartásával tegyék meg a részvételhez szükséges további lépéseket.
            </p>
            <p>
              Minden érdeklődőt sok szeretettel várunk az online konferencián!
            </p>
          </section>
          <section>
            <h2>Elérhetőség</h2>
            <dl className="alt">
              <dt>Szervező</dt>
              <dd><a href="https://www.ttk.pte.hu/">Pécsi Tudományegyetem Természettudományi Kar</a></dd>
              <dt>Email</dt>
              <dd><a href="mailto:ofkd2022@gmail.com">ofkd2022@gmail.com</a></dd>
            </dl>
          </section>
        </footer>

        <div onClick={scrollToTop} id="scroll-top-btn" className="box-shadow" title="Go to top"><i className="fa-solid fa-arrow-up"/></div>

      </div>
    </div>
  );
}

window.onscroll = function() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.getElementById("scroll-top-btn").style.display = "block";
  } else {
    document.getElementById("scroll-top-btn").style.display = "none";
  }
};

function scrollToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

export default App;
